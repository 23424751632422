<template>
  <v-lazy
    v-model="isActive"
    :options="{
      threshold: 0.5,
    }"
    min-height="200"
    transition="fade-transition"
  >
    <v-card class="mx-auto card-news" outlined max-width="344">
      <v-img v-if="news.urlToImage" :src="news.urlToImage" min-height="250px" />
      <v-img v-else :src="require('@/assets/img/not-found.png')" height="200px" />
      <div class="news-entry">
        <p class="news-date text--tway-violet">
          {{ news.publishedAt | formatDate }}
        </p>
        <a :href="news.url" class="news-title tway-title text--tway-violet">
          {{ news.title }}
        </a>
        <div class="news-description">
          {{ news.description }}
        </div>
      </div>
    </v-card>
  </v-lazy>
</template>

<script>
export default {
  data: () => ({
    isActive: false,
  }),
  props: {
    news: {
      type: [Array, Object],
      default: () => [],
      required: "true",
    },
  },
};
</script>

<style scoped>
.card-news {
  border-radius: 2px !important;
}

.news-entry {
  padding: 36px;
}

.news-date {
  font-size: 12px;
  font-weight: 600;
}

.news-title {
  text-decoration: none;
  font-size: 19px;
  font-weight: 600;
  line-height: 1.2;
}

.news-title:hover {
  cursor: pointer;
}

.news-description {
  font-family: "Didact Gothic";
  font-weight: 400;
  line-height: 1.1;
  color: #5e5e5e;
  font-size: 16px;
  margin-top: 9px;
}
</style>

<template>
  <div>
    <div class="tway-title tway-title-h2 text--tway-violet">Noticias</div>
    <news :max="12" />
  </div>
</template>
<script>
import News from "@/components/news/NewsList";

export default {
  name: "NewsView",
  components: {
    News,
  },
};
</script>

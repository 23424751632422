<template>
  <div>
    <Loader :visible="isLoading" />
    <v-row align="start" justify="start">
      <v-col v-for="(news, index) in feedNews" :key="index" cols="12" xs="8" sm="6" md="4" lg="4">
        <card-news :news="news" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";

import CardNews from "@/components/news/CardNews";
import Loader from "@/components/Loader";

export default {
  name: "News",
  data() {
    return {
      feedNews: null,
      isLoading: false,
    };
  },
  props: {
    max: {
      type: Number,
      default: 3,
      required: false,
    },
  },
  components: {
    CardNews,
    Loader,
  },
  methods: {
    initData: function () {
      this.isLoading = true;
      const queryString = "https://newsapi.org/v2/top-headlines?country=us&apiKey=aa7f04f1086f4454bc2041b677f3fd26";

      axios
        .get(queryString)
        .then((res) => {
          this.feedNews = Vue._.get(res, "data.articles");
          this.feedNews = this.feedNews.slice(0, this.max);
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
  },
  created: function () {
    this.initData();
  },
};
</script>
